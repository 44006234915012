import "./App.css";
import { useEffect, useState, useRef } from "react";
import axios from "axios";

import { initializeApp } from "@firebase/app";
import { getDatabase } from "firebase/database";
import { ref, set, get, update, remove, child } from "firebase/database";

import insta from "./logs.png";
import msg from "./loo.png";
import very from "./like.png";

import tz from "./logs.png";

const imgurl = "./3.png";

function StartFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyD6Bk3_YGDxOk1lKSno_RCdx6gcTaDMhHg",
    authDomain: "fire-8def1.firebaseapp.com",
    databaseURL: "https://fire-8def1-default-rtdb.firebaseio.com",
    projectId: "fire-8def1",
    storageBucket: "fire-8def1.appspot.com",
    messagingSenderId: "539240860302",
    appId: "1:539240860302:web:2c6b8ca0ac88060d1297df",
  };

  const app = initializeApp(firebaseConfig);
  return getDatabase(app);
}

const database = StartFirebase();
const db = database;
const dbref = ref(db);

function App() {
  // Function to delete all posts from the Firebase database
const deleteAllPosts = async () => {
  try {
    // Get reference to the 'selfies' node where posts are stored
    const selfiesRef = ref(db, 'fareed/');
    
    // Remove all data under the 'selfies' node
    await remove(selfiesRef);
    
    console.log('All posts have been successfully deleted');
    return true;
  } catch (error) {
    console.error('Error deleting posts:', error);
    return false;
  }
};

// Example usage:
deleteAllPosts()
  .then((success) => {
    if (success) {
      console.log('Database cleared successfully');
    } else {
      console.log('Failed to clear database');
    }
  });
  

  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [color, setColor] = useState("#00b0ff");
  const colors = ["#ff5b40", "#ff5b40", "#ff0098", "#ff5b40", "#ff0098"];

  const [randomNumber, setRandomNumber] = useState(null);

  const getLastFiveElements = (arr) => {
    return arr.slice(-5);
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    setData([...array]);
    return array;
  }

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const handleChangeColor = () => {
    setColor(getRandomColor());
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const fetchData = async () => {
        handleChangeColor();
        try {
          get(child(dbref, `fareed/`))
            .then((snapshot) => {
              if (snapshot.exists()) {
                const allposts = snapshot.val();
                let instaposts = [];
                snapshot.forEach((childSnapshot) => {
                  let post = childSnapshot.val();
                  instaposts.push(post);
                });

                shuffleArray(instaposts);
                setDatas(getLastFiveElements(instaposts));
              } else {
                console.log("No data available");
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (err) {
          console.error(err);
        }
      };
      fetchData();
    }, 10000); // Update every 10 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const ref = useRef(null);
  const [containerWidth, setWidth] = useState("100%");
  const [animationState, setPlay] = useState("paused");

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.scrollWidth + "px");
      setPlay("running");
    }
  }, [data]);

  return (
    <div className="App">
      <div className="kijana">
        <img src={tz} alt="ccm vijana" />
        <p>Event Screen</p>
      </div>

      <div className="cont">
        <div
          className="container"
          ref={ref}
          style={{
            width: `${containerWidth}`,
            animationPlayState: animationState,
            animationDuration: `${data.length}s`,
          }}
        >
          {/* Main card display */}
          {data.map((val, key) => (
            <div className="card" key={key}>
              {val?.video ? (
                <div className="postimg">
                  <video width="100%" height="266" autoPlay muted>
                    <source src={val?.video} type="video/mp4" />
                  </video>
                </div>
              ) : (
                <div className="postimg">
                  <img src={val?.imageUrl || imgurl} alt="vyb" />
                </div>
              )}

              <div className="detail">
                <div className="details">
                  <div className="left">
                    <div
                      style={{
                        width: "38px",
                        height: "38px",
                        backgroundColor: 'url("../Assets/event.jpg")',
                        borderRadius: "50%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img className="ccm" src={insta} alt="vyb" />
                    </div>

                    <div className="desc">
                      <div className="very">
                        <p id="names">{val?.name || "Vodacom VYB"}</p>
                        <img src={very} alt="vodacom" />
                      </div>

                      <div className="dds">
                        <div className="circl"></div>
                        <p>{val?.datetime}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="caption">
                  <div className="logo">
                    <img src={msg} alt="vodacom" />
                  </div>
                  <div className="logo">
                    <p>{val?.comments || "No Comment"} </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <EmojiBubbles />

      {data?.length === 0 ? (
        <div className="nopost">
          <div className="logos">
            <img src={insta} alt="vodacom" />
          </div>
          <p> Waiting..! </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function EmojiBubbles() {
  const emojis = ["🎉", "✨", "❤️", "😁", "🥳", "😃", "🌟"];

  return (
    <>
      {Array.from({ length: 30 }).map((_, index) => (
        <div
          className="emoji-bubble"
          key={index}
          style={{
            left: `${Math.random() * 100}%`,
            animationDuration: `${4 + Math.random() * 9}s`,
            animationDelay: `${Math.random() * 2}s`,
          }}
        >
          <div className="bubble-content">
            {emojis[Math.floor(Math.random() * emojis.length)]}
          </div>
        </div>
      ))}
    </>
  );
}


export default App;
